import React, { useEffect } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import type { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import faMarkerIcon from '../../map/marker-icon.png';
import faMarkerShadow from '../../map/marker-shadow.png';

interface MapWithLayersProps {
  potentialProjectsGeoJson: FeatureCollection<Geometry, GeoJsonProperties>
  potentialAddressesGeoJson: FeatureCollection<Geometry, GeoJsonProperties>
}

export function MapWithLayers({
  potentialProjectsGeoJson,
  potentialAddressesGeoJson
}: MapWithLayersProps) {
  const map = useMap();

  useEffect(() => {
    if (map && map.current) {
      map.current.loadImage(faMarkerIcon, (error, image) => {
        if (error) throw error;
        if (!map.current) return;
        if (image && !map.current.hasImage('marker-icon')) {
          map.current.addImage('marker-icon', image);
        }
      });
      map.current.loadImage(faMarkerShadow, (error, image) => {
        if (error) throw error;
        if (!map.current) return;
        if (image && !map.current.hasImage('marker-shadow')) {
          map.current.addImage('marker-shadow', image);
        }
      });
    }
  }, [map]);

  return (
    <>
      <Source
        id="potentialProjects"
        type="geojson"
        data={potentialProjectsGeoJson}
      >
        <Layer
          id="unclustered-point-shadow"
          type="symbol"
          filter={['!', ['has', 'point_count']]}
          layout={{
            'icon-image': 'marker-shadow',
            'icon-size': 1,
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
            'icon-offset': [8, 0],
          }}
        />
        <Layer
          id="unclustered-point"
          type="symbol"
          filter={['!', ['has', 'point_count']]}
          layout={{
            'icon-image': 'marker-icon',
            'icon-size': 1,
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
            'icon-offset': [8, 0],
          }}
        />
      </Source>
      <Source
        id="potentialAddresses"
        type="geojson"
        data={potentialAddressesGeoJson}
      >
        <Layer
          id="potential-addresses"
          type="circle"
          paint={{
            'circle-radius': 5,
            'circle-color': '#FF0000',
            'circle-opacity': 0.5,
          }}
        />
      </Source>
    </>
  );
}
