import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { getAccessToken } from '../../../../../services/auth.service'
import './groep.css'

// Interfaces
export interface UserType {
  id: string
  firstName: string
  lastName: string
  email: string
}

export enum PermissionGrantType {
  ACCEPT = 'ACCEPT',
  DENY = 'DENY',
  INHERIT = 'INHERIT',
}

export interface PermissionType {
  id: string
  node: string
  create: PermissionGrantType
  read: PermissionGrantType
  update: PermissionGrantType
  delete: PermissionGrantType
  deleted?: boolean // Add optional property for tracking deletions
}

export interface GroupType {
  id: string
  name: string
  isRoot: boolean
  isProvider: boolean
  created: string
  updated: string
  deleted: string | null
  users: UserType[]
  permissions: PermissionType[]
}

export interface TreeNode {
  node: string
  children: TreeNode[]
  permissions?: PermissionType
  isEnabled?: boolean
  created?: boolean
  updated?: boolean
  deleted?: boolean
}

export function Groep() {
  const [originalPermissions, setOriginalPermissions] = useState<PermissionType[]>([])
  const [changelog, setChangelog] = useState<PermissionType[]>([])
  const [renderTree, setRenderTree] = useState<TreeNode[]>([])
  const { id } = useParams()

  const groepQuery = useQuery({
    queryKey: ['groep', id],
    queryFn: async () => {
      const response = await fetch(`/api/users/groups/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json() as Promise<GroupType>
    },
  })

  const permissionQuery = useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const response = await fetch(`/api/permissions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json() as Promise<{ node: string, parent: { node: string } | null }[]>
    },
  })

  useEffect(() => {
    if (groepQuery.data) {
      setOriginalPermissions(groepQuery.data.permissions)
    }
  }, [groepQuery.data])

  useEffect(() => {
    if (permissionQuery.data) {
      const treeData = buildBareTree(permissionQuery.data)
      const treeWithGroupPermissions = applyGroupPermissions(treeData, originalPermissions)
      const finalTree = applyChangelogToTree(treeWithGroupPermissions)

      setRenderTree(finalTree)
    }
  }, [permissionQuery.data, originalPermissions, changelog])

  const buildBareTree = (
    data: { node: string, parent: { node: string } | null }[],
  ): TreeNode[] => {
    const nodeMap: Record<string, TreeNode> = {}
    const roots: TreeNode[] = []

    data.forEach((item) => {
      nodeMap[item.node] = { node: item.node, children: [] }
    })

    data.forEach((item) => {
      if (item.parent?.node) {
        nodeMap[item.parent.node].children.push(nodeMap[item.node])
      }
      else {
        roots.push(nodeMap[item.node])
      }
    })

    return roots
  }

  const applyGroupPermissions = (
    tree: TreeNode[],
    permissions: PermissionType[],
  ): TreeNode[] => {
    const permissionsMap = new Map(permissions.map(perm => [perm.node, perm]))

    const mergePermissions = (nodes: TreeNode[]): TreeNode[] => {
      return nodes.map((node) => {
        const permission = permissionsMap.get(node.node)
        return {
          ...node,
          permissions: permission || undefined,
          isEnabled: !!permission,
          children: mergePermissions(node.children),
        }
      })
    }

    return mergePermissions(tree)
  }

  const applyChangelogToTree = (tree: TreeNode[]): TreeNode[] => {
    const changelogMap = new Map(changelog.map(change => [change.node, change]))

    const applyChanges = (nodes: TreeNode[]): TreeNode[] => {
      return nodes.map((node) => {
        const original = node.permissions
        const change = changelogMap.get(node.node)

        const permissions = change || original

        const created = !original && !!change
        const deleted = !!change?.deleted
        const updated = !!change && original && !deleted

        return {
          ...node,
          permissions,
          isEnabled: !!permissions && !deleted,
          created,
          updated,
          deleted,
          children: applyChanges(node.children),
        }
      })
    }

    return applyChanges(tree)
  }

  const toggleNode = (nodePath: string) => {
    setChangelog((prev) => {
      const existingChange = prev.find(change => change.node === nodePath)
      const original = originalPermissions.find(perm => perm.node === nodePath)

      if (existingChange) {
        if (!original)
          return prev.filter(change => change.node !== nodePath)
        if (existingChange.deleted)
          return prev.filter(change => change.node !== nodePath)
        return [
          ...prev.filter(change => change.node !== nodePath),
          { ...existingChange, deleted: true },
        ]
      }

      if (original) {
        return [...prev, { ...original, deleted: true }]
      }

      return [
        ...prev,
        {
          id: uuidv4(),
          node: nodePath,
          create: PermissionGrantType.INHERIT,
          read: PermissionGrantType.INHERIT,
          update: PermissionGrantType.INHERIT,
          delete: PermissionGrantType.INHERIT,
        },
      ]
    })
  }

  const cyclePermission = (nodePath: string, operation: keyof PermissionType) => {
    setChangelog((prev) => {
      const change = prev.find(c => c.node === nodePath) || {}
      const original = originalPermissions.find(perm => perm.node === nodePath)

      // Ensure `change` has all required fields
      const current
      = (change as PermissionType)[operation]
      || original?.[operation]
      || PermissionGrantType.INHERIT

      const nextState
      = current === PermissionGrantType.INHERIT
        ? PermissionGrantType.ACCEPT
        : current === PermissionGrantType.ACCEPT
          ? PermissionGrantType.DENY
          : PermissionGrantType.INHERIT

      const updatedChange: PermissionType = {
        ...change,
        id: (change as PermissionType).id || original?.id || uuidv4(),
        node: nodePath,
        create: (change as PermissionType).create || original?.create || PermissionGrantType.INHERIT,
        read: (change as PermissionType).read || original?.read || PermissionGrantType.INHERIT,
        update: (change as PermissionType).update || original?.update || PermissionGrantType.INHERIT,
        delete: (change as PermissionType).delete || original?.delete || PermissionGrantType.INHERIT,
        [operation]: nextState,
      }

      // Remove from changelog if it matches the original state
      if (
        original
        && original.create === updatedChange.create
        && original.read === updatedChange.read
        && original.update === updatedChange.update
        && original.delete === updatedChange.delete
      ) {
        return prev.filter(c => c.node !== nodePath)
      }

      return [...prev.filter(c => c.node !== nodePath), updatedChange]
    })
  }

  const renderTreeNodes = (nodes: TreeNode[]): JSX.Element[] => {
    return nodes.map(node => (
      <div
        key={node.node}
        className={`tree-node ${
        node.created ? 'created-node' : ''
      } ${node.deleted ? 'deleted-node' : ''} ${
        node.updated ? 'updated-node' : ''
      }`}
      >
        <div className={`node-header ${!node.isEnabled ? 'faded-text' : ''}`}>
          <label>
            <input
              type="checkbox"
              checked={node.isEnabled}
              onChange={() => toggleNode(node.node)}
            />
            {node.node}
          </label>
        </div>
        {/* Only render permissions if the node is not deleted */}
        {!node.deleted && node.permissions && (
          <div className="permissions">
            <label>
              Create:
              <span
                className={`checkbox ${node.permissions.create.toLowerCase()}`}
                onClick={() => cyclePermission(node.node, 'create')}
              >
                {node.permissions.create === PermissionGrantType.ACCEPT
                  ? '✔'
                  : node.permissions.create === PermissionGrantType.DENY
                    ? '✘'
                    : '➖'}
              </span>
            </label>
            <label>
              Read:
              <span
                className={`checkbox ${node.permissions.read.toLowerCase()}`}
                onClick={() => cyclePermission(node.node, 'read')}
              >
                {node.permissions.read === PermissionGrantType.ACCEPT
                  ? '✔'
                  : node.permissions.read === PermissionGrantType.DENY
                    ? '✘'
                    : '➖'}
              </span>
            </label>
            <label>
              Update:
              <span
                className={`checkbox ${node.permissions.update.toLowerCase()}`}
                onClick={() => cyclePermission(node.node, 'update')}
              >
                {node.permissions.update === PermissionGrantType.ACCEPT
                  ? '✔'
                  : node.permissions.update === PermissionGrantType.DENY
                    ? '✘'
                    : '➖'}
              </span>
            </label>
            <label>
              Delete:
              <span
                className={`checkbox ${node.permissions.delete.toLowerCase()}`}
                onClick={() => cyclePermission(node.node, 'delete')}
              >
                {node.permissions.delete === PermissionGrantType.ACCEPT
                  ? '✔'
                  : node.permissions.delete === PermissionGrantType.DENY
                    ? '✘'
                    : '➖'}
              </span>
            </label>
          </div>
        )}
        <div className="children">{renderTreeNodes(node.children)}</div>
      </div>
    ))
  }

  if (groepQuery.isLoading || permissionQuery.isLoading) {
    return <div>Loading...</div>
  }

  if (groepQuery.error || permissionQuery.error) {
    return (
      <div>
        Error:
        {(groepQuery.error || permissionQuery.error)?.message}
      </div>
    )
  }

  return (
    <div className="permission-container">
      <h1>
        {groepQuery.data?.name}
        {' '}
        Permissions
      </h1>
      <div className="tree">
        {renderTreeNodes(renderTree)}
      </div>
      <h2>Debugging</h2>
      <div>
        <h3>Changelog</h3>
        <pre>{JSON.stringify(changelog, null, 2)}</pre>
      </div>
      <div>
        <h3>Render Tree</h3>
        <pre>{JSON.stringify(renderTree, null, 2)}</pre>
      </div>
    </div>
  )
}
