import type { ChangeEvent } from 'react'
import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faUpload } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import Creatable from 'react-select/creatable'
import axios from 'axios'
import { getAccessToken } from '../services/auth.service'

import './FileUploadModal.css'
import { DarkModeContext } from '../providers/DarkModeProvider'
import { selectTheme } from '../util/helpers'

export enum RelationType {
  CONNECTION = 'connection',
  PROJECT = 'project',
}

interface FileUploadModalProps {
  relationType: RelationType
  relationId: string
  isOpen: boolean
  onClose: () => void
}

const createOptions = [
  { value: 'Civiel', label: 'Civiel' },
  { value: 'Meterkast', label: 'Meterkast' },
  { value: 'Apparatuur', label: 'Apparatuur' },
  { value: 'OTDR', label: 'OTDR' },
  { value: 'RFS', label: 'RFS' },
  { value: 'Schade', label: 'Schade' },
  { value: 'Schouwdocument', label: 'Schouwdocument' },
]

interface selectType {
  value: string
  label: string
}

export function FileUploadModal({
  relationType,
  relationId,
  isOpen,
  onClose,
}: FileUploadModalProps) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const cameraInputRef = useRef<HTMLInputElement>(null)
  const [filename, setFilename] = useState<string>('')
  const [description, setDescription] = React.useState<selectType>({ value: '', label: '' })
  const [note, setNote] = React.useState('')
  const [file, setFile] = React.useState<File | null>(null)

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const queryClient = useQueryClient()

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0]
      setFilename(selectedFile.name)
      setFile(selectedFile) // Store the selected file in state
    }
  }

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file
      if (e.dataTransfer.items[0].kind === 'file') {
        const file = e.dataTransfer.items[0].getAsFile()
        setFilename(file?.name || '')
        setFile(file)
      }
    }
    else {
      // Use DataTransfer interface to access the file
      const file = e.dataTransfer.files[0]
      setFilename(file.name)
      setFile(file)
    }

    // Clear the drag data cache (for all formats/types)
    e.dataTransfer.clearData()
  }

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleCaptureButtonClick = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click()
    }
  }

  const handleSubmit = async () => {
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('relationType', relationType)
    formData.append('relationId', relationId)
    formData.append('description', description.value)
    formData.append('note', note)

    setIsUploading(true)

    try {
      const response = await axios.post('/api/documents', formData, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setUploadProgress(percentCompleted)
          }
        },
      })

      if (response.status === 201) {
        toast.success('File uploaded successfully!')
        queryClient.invalidateQueries({
          queryKey: ['documents', relationId],
        })
        setFile(null)
        setNote('')
        setDescription({ value: '', label: '' })
        setFilename('')
        onClose()
        setIsUploading(false)
      }
      else {
        toast.error('Error uploading the file.')
        setIsUploading(false)
      }
    }
    catch (error) {
      toast.error('Network error. Please try again.')
      setIsUploading(false)
    }
  }

  const handlePaste = (event: React.ClipboardEvent) => {
    const clipboardItems = event.clipboardData.items
    let file: File | null = null

    for (let i = 0; i < clipboardItems.length; i++) {
      if (clipboardItems[i].kind === 'file') {
        file = clipboardItems[i].getAsFile()
        break
      }
    }

    if (file) {
      setFilename(file.name)
      setFile(file)
    }
    else {
      toast.warning('No file found in clipboard.') // Misschien weghalen?
    }
  }

  return (
    <div
      className={`modal fade ${isOpen ? 'show' : ''}`}
      style={isOpen ? { display: 'block' } : {}}
      onDragOver={e => e.preventDefault()}
      onDrop={handleFileDrop}
      onPaste={handlePaste}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          {isUploading && (
            <div className="overlay">
              <svg
                width="100"
                height="100"
                viewBox="0 0 36 36"
                className="circular-progress"
              >
                <circle
                  cx="18"
                  cy="18"
                  r="15.9"
                  className="background"
                />
                <circle
                  cx="18"
                  cy="18"
                  r="15.9"
                  className="progress"
                  strokeDasharray={`${uploadProgress}, 100`}
                />
                <text x="18" y="18" textAnchor="middle" dominantBaseline="middle" fontSize="10" fill="white" transform="rotate(90 18,18)">{uploadProgress}</text>

              </svg>
            </div>
          )}

          <div className="modal-header">
            <h4 className="modal-title">Upload Bestand</h4>
            <button
              type="button"
              className="close"
              onClick={() => {
                setFile(null)
                setNote('')
                setDescription({ value: '', label: '' })
                setFilename('')
                onClose()
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">

            <label htmlFor="description">Description</label>
            <Creatable
              theme={selectStyle}
              className="mb-3"
              options={createOptions}
              value={description}
              onChange={e => setDescription(e || { value: '', label: '' })}
              onCreateOption={(e) => {
                setDescription({ value: e, label: e })
              }}
            />
            <label htmlFor="filename">Bestand</label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                id="filename"
                value={filename}
                placeholder="No file selected"
                readOnly
                onClick={handleUploadButtonClick}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={handleUploadButtonClick}>
                  {isMobile ? <FontAwesomeIcon icon={faUpload} /> : 'Upload File'}
                </button>
                {isMobile
                && (
                  <button className="btn btn-outline-primary" type="button" onClick={handleCaptureButtonClick}>
                    <FontAwesomeIcon icon={faCamera} />
                  </button>
                )}
              </div>
              <input
                id="fileUpload"
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {isMobile
              && (
                <input
                  id="cameraCapture"
                  type="file"
                  accept="image/*"
                  capture
                  ref={cameraInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              )}
            </div>
            <label htmlFor="note">Notities</label>
            <textarea
              className="form-control mb-3"
              placeholder="Provide a note..."
              id="note"
              value={note}
              onChange={e => setNote(e.target.value)}
            >
            </textarea>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Upload</button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setFile(null)
                setNote('')
                setDescription({ value: '', label: '' })
                setFilename('')
                onClose()
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
