import React, { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import Button from '../../components/Button'
import { setWindowClass } from '../../util/helpers'
import { setIn } from 'formik'

function RecoverPassword() {
  const location = useLocation()
  const [tokenid, setTokenid] = useState('')
  const [token, setToken] = useState('')
  const [validToken, setValidToken] = useState(false)
  const [invalidToken, setInvalidToken] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const [passwordChanged, setPasswordChanged] = useState(false)
  const [passwordChangeError, setPasswordChangeError] = useState('')

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    if (e.target.value.length < 6) {
      setPasswordError('Password must be at least 6 characters long')
    }
    else {
      setPasswordError('')
    }
  }

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
    if (e.target.value !== password) {
      setConfirmPasswordError('Passwords do not match')
    }
    else {
      setConfirmPasswordError('')
    }
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    setPasswordChangeError('')
    e.preventDefault()
    if (!passwordError && !confirmPasswordError) {
      const result = await fetch('api/auth/password-reset/complete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tokenid,
          token,
          password,
        }),
      })

      if (result.ok) {
        setPasswordChanged(true)
      }
      else {
        const body = await result.json()
        setPasswordChangeError(body.message)
      }
    }
  }

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tokenid = params.get('tokenid')
    const token = params.get('token')

    if (tokenid && token) {
      setInvalidToken(false)
      setTokenid(tokenid)
      setToken(token)
    }
  }, [location])

  React.useEffect(() => {
    if (tokenid && token) {
      fetch(`api/auth/password-reset/validate?tokenid=${encodeURIComponent(tokenid)}&token=${encodeURIComponent(token)}`)
        .then((response) => {
          if (response.ok) {
            setValidToken(true)
            setInvalidToken(false)
          }
          else {
            setValidToken(false)
            setInvalidToken(true)
          }
        })
    }
  }, [tokenid, token])

  setWindowClass('hold-transition login-page')

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h2">
            <b>DATAFIBER</b>
            <span>NETWORK</span>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">Stel een nieuw wachtwoord in</p>
          {passwordChanged && (
            <div className="alert alert-success" role="alert">
              Wachtwoord is succesvol veranderd
            </div>
          )}
          {passwordChangeError && !passwordChanged && (
            <div className="alert alert-danger" role="alert">
              {passwordChangeError}
            </div>
          )}
          {!validToken && !invalidToken && !passwordChanged && (
            <div className="alert alert-warning" role="alert">
              Token wordt gecontrolleerd...
            </div>
          )}
          {validToken && !passwordChanged && (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <InputGroup className="mb-3">
                  <Form.Control
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={handlePasswordChange}
                    value={password}
                    isValid={!!password && !passwordError}
                    isInvalid={!!passwordError}
                  />
                  {passwordError
                    ? (
                        <Form.Control.Feedback type="invalid">
                          {passwordError}
                        </Form.Control.Feedback>
                      )
                    : (
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faLock} />
                        </InputGroup.Text>
                      )}
                </InputGroup>
              </div>
              <div className="mb-3">

                <InputGroup className="mb-3">
                  <Form.Control
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm password"
                    onChange={handleConfirmPasswordChange}
                    value={confirmPassword}
                    isValid={!!confirmPassword && !confirmPasswordError}
                    isInvalid={!!confirmPasswordError}
                  />
                  {confirmPasswordError
                    ? (
                        <Form.Control.Feedback type="invalid">
                          {confirmPasswordError}
                        </Form.Control.Feedback>
                      )
                    : (
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faLock} />
                        </InputGroup.Text>
                      )}
                </InputGroup>
              </div>
              <div className="row">
                <div className="col-12">
                  <Button type="submit" block>
                    Wachtwoord Veranderen
                  </Button>
                </div>
              </div>
            </form>
          )}
          {invalidToken && !passwordChanged && (
            <div className="alert alert-danger" role="alert">
              Deze link is niet geldig. Vraag een nieuwe link aan.
            </div>
          )}

          <p className="mt-3 mb-1">
            <Link to="/login">Terug naar Login</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default RecoverPassword
