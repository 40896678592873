import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Card, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from '../../../../services/auth.service'

interface GroepenApiResponseType {
  id: string
  name: string
  isRoot: boolean
  isProvider: boolean
  created: string
  updated: string
  deleted: string
}

interface GroepenType {
  id: string
  name: string
  isRoot: boolean
  isProvider: boolean
  created: Date
  updated: Date
  deleted: Date | null
}

export function Groepen() {
  const [groepen, setGroepen] = React.useState<GroepenType[]>()
  const navigate = useNavigate()

  const { isLoading, error, data } = useQuery({
    queryKey: ['groepen'],
    queryFn: async () => {
      const response = await fetch(`/api/users/groups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json() as Promise<GroepenApiResponseType[]>
    },
  })

  React.useEffect(() => {
    if (data) {
      setGroepen(
        data.map(groep => ({
          id: groep.id,
          name: groep.name,
          isRoot: groep.isRoot,
          isProvider: groep.isProvider,
          created: new Date(groep.created),
          updated: new Date(groep.updated),
          deleted: groep.deleted ? new Date(groep.deleted) : null,
        })),
      )
    }
  }, [data])

  return (
    <Card>
      <Card.Header>
        <h5 className="card-title">Groepen</h5>
      </Card.Header>
      <Card.Body>
        {isLoading && (
          <div className="alert alert-info" role="alert">
            Loading...
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            Error:
            {' '}
            {(error as Error).message}
          </div>
        )}

        {groepen && groepen.length === 0 && (
          <div className="alert alert-info" role="alert">
            Geen groepen gevonden.
          </div>
        )}

        {groepen && groepen.length > 0 && (
          <Table responsive striped>
            <thead>
              <tr>
                <th>Naam</th>
                <th>Provider</th>
                <th>Toegevoegd</th>
                <th>Bijgewerkt</th>
              </tr>
            </thead>
            <tbody>
              {groepen.map(groep => (
                <tr key={groep.id} onDoubleClick={() => navigate(`/settings/toegang/groepen/${groep.id}`)}>
                  <td>{groep.name}</td>
                  <td>{groep.isProvider ? 'Ja' : 'Nee'}</td>
                  <td>{groep.created.toLocaleString()}</td>
                  <td>{groep.updated.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  )
}
