import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAccessToken } from '../../../services/auth.service'
import { Card } from 'react-bootstrap'

interface PotentialAddress {
  num: string
  street: string
  city: string
  homeNumber: number
  homeLetter: string
  homeAddition: string
  zipcode: string
  geometry: string
}

export function PotentialProject() {
  const { id, index } = useParams()
  const [adresses, setAdresses] = useState<PotentialAddress[]>([])

  const potentialAddressQuery = useQuery({
    queryKey: ['potentialAddress', id, index],
    enabled: !!index,
    queryFn: async () => {
      const response = await fetch(`/api/bag/analyzer/${id}/${index}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  useEffect(() => {
    if (potentialAddressQuery.data) {
      setAdresses(potentialAddressQuery.data.map((address: any) => ({
        num: address.num,
        street: address.street,
        city: address.city,
        homeNumber: address.homeNumber,
        homeLetter: address.homeLetter,
        homeAddition: address.homeAddition,
        zipcode: address.zipcode,
        geometry: address.geometry,
      })))
    }
  }, [potentialAddressQuery.data])

  return (
    <>
      <Card className="card-outline card-primary">
        <Card.Header>
          <Card.Title>Potentieel Project</Card.Title>
        </Card.Header>
        <Card.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>BAG ID</th>
                <th>Straat</th>
                <th>Plaats</th>
                <th>Postcode</th>
              </tr>
            </thead>
            <tbody>
              {adresses.map((address, index) => (
                <tr key={index}>
                  <td>{address.num}</td>
                  <td>{`${address.street} ${address.homeNumber}${address.homeLetter}${address.homeAddition ? `-${address.homeAddition}` : ``}`}</td>
                  <td>{address.city}</td>
                  <td>{address.zipcode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </>
  )
}