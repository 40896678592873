import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../components/Button'
import { setWindowClass } from '../../util/helpers'

function ForgotPassword() {
  const [requestSent, setRequestSent] = React.useState(false)

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      // eslint-disable-next-line no-console
      console.log(values)
      const result = await fetch('api/auth/password-reset/request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: values.email,
        }),
      })

      if (result.ok) {
        setRequestSent(true)
      } else {
        const body = await result.json()

        if (body.error) {
          body.message.forEach((message: string) => {
            toast.error(message)
          })
        }
      }
    },
  })

  setWindowClass('hold-transition login-page')

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h2">
            <b>DATAFIBER</b>
            <span>NETWORK</span>
          </Link>
        </div>
        <div className="card-body">
          {requestSent && (
            <div className="alert alert-success" role="alert">
              Als er een account met dat e-mailadres bestaat, is er een resetlink verzonden.
            </div>
          )}
          {!requestSent && (
            <>
              <p className="login-box-msg">Wachtwoord Vergeten</p>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.email}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    {touched.email && errors.email
                      ? (
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        )
                      : (
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                        )}
                  </InputGroup>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Button type="submit" block>
                      Vraag nieuw wachtwoord aan.
                    </Button>
                  </div>
                </div>
              </form>
            </>
          )}
          <p className="mt-3 mb-1">
            <Link to="/login">Terug naar Login</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
