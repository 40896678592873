import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAnchor,
  faBuilding,
  faCaravan,
  faCity,
  faHashtag,
  faHouseUser,
  faRoad,
} from '@fortawesome/free-solid-svg-icons'
import { getAccessToken } from '../../../../services/auth.service'

/*
id	25
zipFileName	"cb121861f733b1153717178dde21001677a10eaa6e5a96f66af4654ba94f9b7c8e.zip"
workingDirectory	"/cephfs/bagimport/25/"
type	"mutation"
status	"complete"
extractionStatus	"complete"
analysisStatus	"complete"
processingStatus	"complete"
optimizeStatus	"complete"
bagExtractVersion	"20241108-20241208"
WPL_count	12
OPR_count	243
NUM_count	36906
PND_count	163654
LIG_count	37
STA_count	916
VBO_count	163448
MUT_count	19
WPL_progress	6
OPR_progress	30
NUM_progress	12171
PND_progress	72404
LIG_progress	6
STA_progress	127
VBO_progress	75792
MUT_progress	19
WPL_extract	0
OPR_extract	0
NUM_extract	0
PND_extract	0
LIG_extract	0
STA_extract	0
VBO_extract	0
MUT_extract	19
WPL_optimize_count	2621
WPL_optimize_progress	2621
OPR_optimize_count	3498450
OPR_optimize_progress	3498450
NUM_search_count	67821
NUM_search_progress	67820
createdAt	"2025-01-07T13:11:27.540Z"
updatedAt	"2025-01-07T13:43:36.000Z"
deletedAt	null
 */

export function ImportJob() {
  const { id } = useParams()

  const progressQuery = useQuery({
    queryKey: ['bag', 'import', id],
    queryFn: async () => {
      const response = await fetch(`/api/bag/import/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
    refetchInterval: 1000,
  })

  if (progressQuery.isLoading) {
    return <div>Loading...</div>
  }

  if (progressQuery.isError) {
    return <div>Error</div>
  }

  const progress = progressQuery.data

  // Card color:
  // - pending: gray
  // - running: primary
  // - complete: success
  // - error: danger
  let analysisCardStatus
  let extractionCardStatus
  let processingCardStatus
  let optimizeCardStatus

  switch (progress.analysisStatus) {
    case 'pending':
      analysisCardStatus = 'card card-gray'
      break
    case 'running':
      analysisCardStatus = 'card card-primary'
      break
    case 'complete':
      analysisCardStatus = 'card card-success'
      break
    case 'failed':
      analysisCardStatus = 'card card-danger'
      break
  }

  switch (progress.extractionStatus) {
    case 'pending':
      extractionCardStatus = 'card card-gray'
      break
    case 'running':
      extractionCardStatus = 'card card-primary'
      break
    case 'complete':
      extractionCardStatus = 'card card-success'
      break
    case 'failed':
      extractionCardStatus = 'card card-danger'
      break
  }

  switch (progress.processingStatus) {
    case 'pending':
      processingCardStatus = 'card card-gray'
      break
    case 'running':
      processingCardStatus = 'card card-primary'
      break
    case 'complete':
      processingCardStatus = 'card card-success'
      break
    case 'failed':
      processingCardStatus = 'card card-danger'
      break
  }

  switch (progress.optimizeStatus) {
    case 'pending':
      optimizeCardStatus = 'card card-gray'
      break
    case 'running':
      optimizeCardStatus = 'card card-primary'
      break
    case 'complete':
      optimizeCardStatus = 'card card-success'
      break
    case 'failed':
      optimizeCardStatus = 'card card-danger'
      break
  }

  return (
    <>
      {/* General information card */}
      <div className="card card-primary card-outline">
        <div className="card-header">
          <h3 className="card-title">Algemene informatie</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Volgnummer</td>
                    <td>{progress.id}</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{progress.type}</td>
                  </tr>
                  <tr>
                    <td>Werkmap</td>
                    <td>{progress.workingDirectory}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{progress.status}</td>
                  </tr>
                  <tr>
                    <td>Aangemaakt</td>
                    <td>{new Date(progress.createdAt).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>Laatst bijgewerkt</td>
                    <td>{new Date(progress.updatedAt).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Analysis card */}
      <div className={analysisCardStatus}>
        <div className="card-header">
          <h3 className="card-title">Analyse</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              Analyseren van bestand...
            </div>
          </div>
        </div>
      </div>

      {/* Extraction card */}
      <div className={extractionCardStatus}>
        <div className="card-header">
          <h3 className="card-title">Extractie</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Bestand</th>
                    <th style={{ width: '85%' }}>Voortgang</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Teller</th>
                  </tr>
                </thead>
                <tbody>
                  {progress.type === 'mutation' && (
                    <tr>
                      <td>MUT</td>
                      <td>
                        <div className="progress progress-xl">
                          <div className="progress-bar bg-primary" style={{ width: `${progress.MUT_extract / progress.MUT_count * 100}%` }}>
                            {Math.round(progress.MUT_extract / progress.MUT_count * 100_00) / 100}
                            {' '}
                            %
                          </div>
                        </div>
                      </td>
                      <td>
                        {progress.MUT_extract}
                        {' '}
                        /
                        {progress.MUT_count}
                      </td>
                    </tr>

                  )}

                  {progress.type === 'full' && (
                    <>
                      <tr>
                        <td>WPL</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.WPL_extract / progress.WPL_count * 100}%` }}>
                              {Math.round(progress.WPL_extract / progress.WPL_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.WPL_extract}
                          {' '}
                          /
                          {progress.WPL_count}
                        </td>
                      </tr>
                      <tr>
                        <td>OPR</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.OPR_extract / progress.OPR_count * 100}%` }}>
                              {Math.round(progress.OPR_extract / progress.OPR_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.OPR_extract}
                          {' '}
                          /
                          {progress.OPR_count}
                        </td>
                      </tr>
                      <tr>
                        <td>NUM</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.NUM_extract / progress.NUM_count * 100}%` }}>
                              {Math.round(progress.NUM_extract / progress.NUM_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.NUM_extract}
                          {' '}
                          /
                          {progress.NUM_count}
                        </td>
                      </tr>
                      <tr>
                        <td>LIG</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.LIG_extract / progress.LIG_count * 100}%` }}>
                              {Math.round(progress.LIG_extract / progress.LIG_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.LIG_extract}
                          {' '}
                          /
                          {progress.LIG_count}
                        </td>
                      </tr>
                      <tr>
                        <td>STA</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.STA_extract / progress.STA_count * 100}%` }}>
                              {Math.round(progress.STA_extract / progress.STA_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.STA_extract}
                          {' '}
                          /
                          {progress.STA_count}
                        </td>
                      </tr>
                      <tr>
                        <td>PND</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.PND_extract / progress.PND_count * 100}%` }}>
                              {Math.round(progress.PND_extract / progress.PND_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.PND_extract}
                          {' '}
                          /
                          {progress.PND_count}
                        </td>
                      </tr>
                      <tr>
                        <td>VBO</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.VBO_extract / progress.VBO_count * 100}%` }}>
                              {Math.round(progress.VBO_extract / progress.VBO_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.VBO_extract}
                          {' '}
                          /
                          {progress.VBO_count}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Processing card */}
      <div className={processingCardStatus}>
        <div className="card-header">
          <h3 className="card-title">Verwerking</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Bestand</th>
                    <th style={{ width: '85%' }}>Voortgang</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Teller</th>
                  </tr>
                </thead>
                <tbody>
                  {progress.type === 'mutation' && (
                    <tr>
                      <td>MUT</td>
                      <td>
                        <div className="progress progress-xl">
                          <div className="progress-bar bg-primary" style={{ width: `${progress.MUT_progress / progress.MUT_count * 100}%` }}>
                            {Math.round(progress.MUT_progress / progress.MUT_count * 100_00) / 100}
                            {' '}
                            %
                          </div>
                        </div>
                      </td>
                      <td>
                        {progress.MUT_progress}
                        {' '}
                        /
                        {progress.MUT_count}
                      </td>
                    </tr>
                  )}
                  {progress.type === 'full' && (
                    <>
                      <tr>
                        <td>WPL</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.WPL_progress / progress.WPL_count * 100}%` }}>
                              {Math.round(progress.WPL_progress / progress.WPL_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.WPL_progress}
                          {' '}
                          /
                          {progress.WPL_count}
                        </td>
                      </tr>
                      <tr>
                        <td>OPR</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.OPR_progress / progress.OPR_count * 100}%` }}>
                              {Math.round(progress.OPR_progress / progress.OPR_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.OPR_progress}
                          {' '}
                          /
                          {progress.OPR_count}
                        </td>
                      </tr>
                      <tr>
                        <td>NUM</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.NUM_progress / progress.NUM_count * 100}%` }}>
                              {Math.round(progress.NUM_progress / progress.NUM_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.NUM_progress}
                          {' '}
                          /
                          {progress.NUM_count}
                        </td>
                      </tr>
                      <tr>
                        <td>LIG</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.LIG_progress / progress.LIG_count * 100}%` }}>
                              {Math.round(progress.LIG_progress / progress.LIG_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.LIG_progress}
                          {' '}
                          /
                          {progress.LIG_count}
                        </td>
                      </tr>
                      <tr>
                        <td>STA</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.STA_progress / progress.STA_count * 100}%` }}>
                              {Math.round(progress.STA_progress / progress.STA_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.STA_progress}
                          {' '}
                          /
                          {progress.STA_count}
                        </td>
                      </tr>
                      <tr>
                        <td>PND</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.PND_progress / progress.PND_count * 100}%` }}>
                              {Math.round(progress.PND_progress / progress.PND_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.PND_progress}
                          {' '}
                          /
                          {progress.PND_count}
                        </td>
                      </tr>
                      <tr>
                        <td>VBO</td>
                        <td>
                          <div className="progress progress-xl">
                            <div className="progress-bar bg-primary" style={{ width: `${progress.VBO_progress / progress.VBO_count * 100}%` }}>
                              {Math.round(progress.VBO_progress / progress.VBO_count * 100_00) / 100}
                              {' '}
                              %
                            </div>
                          </div>
                        </td>
                        <td>
                          {progress.VBO_progress}
                          {' '}
                          /
                          {progress.VBO_count}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              {/* Mutation _progress and _count is reused. _progress contains the number of changed rows where _count contains the number of added rows. Note, every change is acompanied by an add, so to get the new rows without change, multiply the changes by 2 and substract from total added. For each type display a mini card with changed & added */}
              {progress.type === 'mutation' && (
                <>
                  <div className="row mt-3">
                    {[
                      { code: 'WPL', label: 'Woonplaats', icon: faCity },
                      { code: 'OPR', label: 'Openbare Ruimte', icon: faRoad },
                      { code: 'NUM', label: 'Nummeraanduiding', icon: faHashtag },
                      { code: 'PND', label: 'Pand', icon: faBuilding },
                      { code: 'STA', label: 'Standplaats', icon: faCaravan },
                      { code: 'LIG', label: 'Ligplaats', icon: faAnchor },
                      { code: 'VBO', label: 'Verblijfsobject', icon: faHouseUser },
                    ].map(({ code, label, icon }) => {
                      const changedRows = progress[`${code}_progress`]
                      const addedRows = progress[`${code}_count`]
                      // Calculate "newly added" rows without a change:
                      const newlyAddedNoChange = addedRows - 2 * changedRows

                      return (
                        <div className="col-md-6 col-lg-3" key={code}>
                          <div className="info-box bg-light">
                            <span className="info-box-icon text-info">
                              <FontAwesomeIcon icon={icon} />
                            </span>
                            <div className="info-box-content">
                              <span className="info-box-text font-weight-bold">{label}</span>
                              <span className="info-box-number">
                                Gewijzigd:
                                {' '}
                                {changedRows}
                              </span>
                              <span className="info-box-number">
                                Toegevoegd:
                                {' '}
                                {newlyAddedNoChange < 0 ? 0 : newlyAddedNoChange}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}

            </div>
          </div>
        </div>
      </div>

      {/* Optimize card */}
      <div className={optimizeCardStatus}>
        <div className="card-header">
          <h3 className="card-title">Optimalisatie</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Bestand</th>
                    <th style={{ width: '85%' }}>Voortgang</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Teller</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>WPL</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.WPL_optimize_progress / progress.WPL_optimize_count * 100}%` }}>
                          {Math.round(progress.WPL_optimize_progress / progress.WPL_optimize_count * 100_00) / 100}
                          {' '}
                          %
                        </div>
                      </div>
                    </td>
                    <td>
                      {progress.WPL_optimize_progress}
                      {' '}
                      /
                      {progress.WPL_optimize_count}
                    </td>
                  </tr>
                  <tr>
                    <td>OPR</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.OPR_optimize_progress / progress.OPR_optimize_count * 100}%` }}>
                          {Math.round(progress.OPR_optimize_progress / progress.OPR_optimize_count * 100_00) / 100}
                          {' '}
                          %
                        </div>
                      </div>
                    </td>
                    <td>
                      {progress.OPR_optimize_progress}
                      {' '}
                      /
                      {progress.OPR_optimize_count}
                    </td>
                  </tr>
                  <tr>
                    <td>NUM</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.NUM_search_progress / progress.NUM_search_count * 100}%` }}>
                          {Math.round(progress.NUM_search_progress / progress.NUM_search_count * 100_00) / 100}
                          {' '}
                          %
                        </div>
                      </div>
                    </td>
                    <td>
                      {progress.NUM_search_progress}
                      {' '}
                      /
                      {progress.NUM_search_count}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
