import { useQuery, useQueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getAccessToken } from '../../services/auth.service'

interface BagAnalyzers {
  id: string
  fromDate: Date
  toDate: Date
  created_at: Date
  potentialProjects: number
}

export function BusinessUnitFinder() {
  const [bagAnalyzers, setBagAnalyzers] = React.useState<BagAnalyzers[]>([])
  const [modalOpen, setModalOpen] = React.useState(false)

  const navigate = useNavigate()

  const bagAnalyzersQuery = useQuery({
    queryKey: ['bagAnalyzers'],
    queryFn: async () => {
      const response = await fetch('/api/bag/analyzer', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (bagAnalyzersQuery.data) {
      setBagAnalyzers(bagAnalyzersQuery.data.map((bagAnalyzer: any) => ({
        id: bagAnalyzer.id,
        fromDate: new Date(bagAnalyzer.fromDate),
        toDate: new Date(bagAnalyzer.toDate),
        created_at: new Date(bagAnalyzer.created_at),
        potentialProjects: bagAnalyzer.potentialProjects,
      })))
    }
  }, [bagAnalyzersQuery.data])

  return (
    <>
      <Card className="card-outline card-primary">
        <Card.Header>
          <Card.Title>BU Finder</Card.Title>
          <div className="card-tools">
            <Button variant="primary" onClick={() => setModalOpen(true)}>Nieuwe Analyse</Button>
          </div>
        </Card.Header>
        <Card.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Datum Analyse</th>
                <th>Datum Vanaf</th>
                <th>Datum Tot</th>
                <th>Potentiele Projecten</th>
              </tr>
            </thead>
            <tbody>
              {bagAnalyzers.map(bagAnalyzer => (
                <tr key={bagAnalyzer.id} onDoubleClick={() => navigate(`/bag/bufinder/${bagAnalyzer.id}`)}>
                  <td>{bagAnalyzer.created_at.toLocaleDateString()}</td>
                  <td>{bagAnalyzer.fromDate.toLocaleDateString()}</td>
                  <td>{bagAnalyzer.toDate.toLocaleDateString()}</td>
                  <td>{bagAnalyzer.potentialProjects}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Body>
      </Card>

      <CreateBUAnalysisModal show={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  )
}

interface CreateBUAnalysisModalProps {
  show: boolean
  handleClose: () => void
}

export function CreateBUAnalysisModal({ show, handleClose }: CreateBUAnalysisModalProps) {
  const [fromDate, setFromDate] = React.useState('')
  const [toDate, setToDate] = React.useState('')

  const queryClient = useQueryClient()

  const handleCreate = async () => {
    if (!fromDate || !toDate) {
      toast.error('Beide datums zijn verplicht.')
      return
    }

    const response = await fetch('/api/bag/analyzer', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fromDate,
        toDate,
      }),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het aanmaken van de BU-analyse.')
      throw new Error(response.statusText)
    }

    toast.success('BU-analyse succesvol aangemaakt.')
    queryClient.invalidateQueries({
      queryKey: ['bagAnalyzers'],
    })

    setFromDate('')
    setToDate('')
    handleClose()
  }

  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Nieuwe BU Analyse</h5>
            <button type="button" className="close" onClick={handleClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="fromDate">Datum Vanaf:</label>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                value={fromDate}
                onChange={e => setFromDate(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="toDate">Datum Tot:</label>
              <input
                type="date"
                id="toDate"
                className="form-control"
                value={toDate}
                onChange={e => setToDate(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Annuleer</button>
            <button type="button" className="btn btn-primary" onClick={handleCreate}>Maak Analyse</button>
          </div>
        </div>
      </div>
    </div>
  )
}
