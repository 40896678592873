/* eslint-disable no-control-regex */
export async function sleep(time: number) {
  return await new Promise(res => setTimeout(res, time))
}

export function setWindowClass(classList: string) {
  const window: HTMLElement | null
    = document && document.getElementById('root')
  if (window != null) {
    // @ts-expect-error asdfasdf
    window.classList = classList
  }
}
export function addWindowClass(classList: string) {
  const window: HTMLElement | null
    = document && document.getElementById('root')
  if (window != null) {
    window.classList.add(classList)
  }
}

export function removeWindowClass(classList: string) {
  const window: HTMLElement | null
    = document && document.getElementById('root')
  if (window != null) {
    window.classList.remove(classList)
  }
}

export function getScreenSize() {
  const width = window.innerWidth
  if (width < 576) {
    return 'xs'
  }
  else if (width < 768) {
    return 'sm'
  }
  else if (width < 992) {
    return 'md'
  }
  else if (width < 1200) {
    return 'lg'
  }
  else {
    return 'xl'
  }
}

export function selectTheme(isDarkMode: boolean, theme: any) {
  if (!isDarkMode) {
    return theme
  }

  return ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#3f6791', // blue
      primary25: 'var(--gray)', // hover Dropdown
      primary50: 'var(--gray)', // hover
      primary75: 'var(--gray)', // hover
      danger: 'var(--danger)', // red
      dangerLight: 'var(--danger)', // red
      neutral0: 'var(--dark)', // background
      neutral5: 'var(--dark-gray)', // Disabled
      neutral10: 'var(--gray)', // Disabled Border
      neutral20: 'var(--gray)', // Active Border / dropdown indicator / seperator
      neutral30: '#3f6791', // Active Hover
      neutral40: 'var(--gray)', //
      neutral50: 'var(--gray)', // Placeholder
      neutral60: '#3f6791', // Dropdown Indicator Active
      neutral70: 'var(--gray)', //
      neutral80: 'var(--white)', // active text
      neutral90: 'var(--gray)', //
    },
  })
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0)
    return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export function prettySpeed(speed: number): string {
  if (speed === -5)
    return 'Cable Only'
  if (speed === -4)
    return 'DWDM Wave'
  if (speed === -3)
    return 'CWDM Wave'
  if (speed === -2)
    return 'Dark Fiber'
  if (speed === -1)
    return 'FTU-Only'
  if (speed === 0)
    return 'Inactief'

  return `${speed} Mbit/s`
}

export function prettyProduct(product: string): string {
  switch (product) {
    case 'fiber':
      return 'Fiber'
    case 'air':
      return 'Straalzender'
    case 'vdsl':
      return 'VDSL'
    case 'starlink':
      return 'Starlink'
    default:
      return 'Unknown'
  }
}

interface WorkflowStatus {
  name: string
  description?: string
  index: number
  notes?: string
  status: string
  created_at: string
}

export function getWorkflowStatus(status: WorkflowStatus[]): string {
  if (status.length === 0)
    return `-`
  // Find best status to show
  let bestStatus = status[0]
  for (let i = 1; i < status.length; i++) {
    if (status[i].index > bestStatus.index && status[i].status !== 'niet_gestart') {
      bestStatus = status[i]
    }
  }
  if (bestStatus.status !== `niet_gestart`) {
    return `${bestStatus.name}`
  }
  else {
    return `-`
  }
}

export interface ApiResponse {
  total_rows: number
  total_pages: number
  page: number
  result: unknown[]
}

export function readFileAsText(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => resolve(e.target?.result)
    reader.onerror = e => reject(e)
    reader.readAsText(file) // For text files
  })
}

export function toBasicASCII(str: string | undefined): string {
  if (!str)
    return '' // Handle undefined or empty strings
  return String(str) // Ensure the input is treated as a string
    .normalize('NFD') // Decompose characters with accents
    .replace(/[\u0300-\u036F]/g, '') // Remove diacritical marks
    .replace(/[^\x00-\x7F]/g, '') // Remove non-ASCII characters
}
