import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { getAccessToken } from '../../../../services/auth.service'
import { printAccessPointSticker, printBoxSticker, printWifiSticker } from '../../../../util/dymo'

interface Router {
  id: string
  serialNumber: string
  model: string
  pppoeUsername: string
  pppoePassword: string
  wifiSSID: string
  wifiPassword: string
  status: string
  configVersion: string
  superAdminPassword: string
}

export function Router() {
  const { id } = useParams()
  const routerQuery = useQuery({
    queryKey: ['provisioning/router', id],

    queryFn: async () => {
      const queryString = `/api/provisioning/router/${id}`

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  if (!routerQuery.data)
    return (<div>Loading...</div>)

  if (routerQuery.error)
    return (<div>Unable to load data...</div>)

  const router: Router = routerQuery.data

  return (
    <div>
      <h1>
        Router:
        {router.wifiSSID}
      </h1>

      Router ID:
      {' '}
      {router.id}
      <br />
      Serienummer:
      {' '}
      {router.serialNumber}
      <br />
      Model:
      {' '}
      {router.model}
      <br />
      PPPoE gebruikersnaam:
      {' '}
      {router.pppoeUsername}
      <br />
      PPPoE wachtwoord:
      {' '}
      {router.pppoePassword}
      <br />
      Wifi SSID:
      {' '}
      {router.wifiSSID}
      <br />
      Wifi wachtwoord:
      {' '}
      {router.wifiPassword}
      <br />
      Status:
      {' '}
      {router.status}
      <br />
      Config versie:
      {' '}
      {router.configVersion}
      <br />
      Superadmin Wachtwoord:
      {' '}
      {router.superAdminPassword}
      {' '}
      <FontAwesomeIcon icon={faCopy} onClick={() => navigator.clipboard.writeText(router.superAdminPassword)} style={{ cursor: 'pointer', color: 'gray' }} />
      <br />
      <br />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => printWifiSticker(router)}
        // disabled={router.status !== 'provisioned'}
      >
        Print Router Sticker
      </button>
      <br />
      {' '}
      <br />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => printAccessPointSticker(router)}
      // disabled={router.status !== 'provisioned'}
      >
        Print Access Point Sticker
      </button>
      <br />
      <br />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => printBoxSticker(router)}
        // disabled={router.status !== 'provisioned'}
      >
        Print Box Sticker
      </button>

    </div>
  )
}
