import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import proj4 from 'proj4'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { Card, Col, Container, Row } from 'react-bootstrap'
import * as Mapbox from 'react-map-gl'
import { Layer, Source, useMap } from 'react-map-gl'
import { getAccessToken } from '../../../services/auth.service'
import { epsg28992Projection } from '../../map/constants'
import { MapWithLayers } from './PotentialProjectsMapWithLayers'

interface PotentialAddress {
  num: string
  street: string
  city: string
  homeNumber: number
  homeLetter: string
  homeAddition: string
  zipcode: string
  geometry: string
}

interface PotentialProject {
  id: string
  index: number
  bagNumsCount: number
  location: {
    centerPoint: string
    centerPointRadius: number
    city: {
      id: string
      name: string
    }
  }
  closestProject: {
    id: string
    name: string
    distance: number
  }
}

interface BagAnalyzers {
  id: string
  fromDate: Date
  toDate: Date
  created_at: Date
  potentialProjects: PotentialProject[]
}

interface ViewState {
  longitude: number
  latitude: number
  zoom: number
}

function prettyDistance(distance: number): string {
  if (distance < 1000) {
    return `${distance} m`
  }
  else {
    return `${(distance / 1000).toFixed(2)} km`
  }
}

const MAPBOX_TOKEN = 'pk.eyJ1IjoibWV0cm90eXJhbm5vIiwiYSI6ImNsbnFvYms5ZTB5b3MycW1weGt2NGg0b2cifQ.qjvD0HaDEy4Gi4TSpFASRA'

let timeout: ReturnType<typeof setTimeout> | undefined

function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
  return (...args: Parameters<T>) => {
    if (timeout)
      clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

// Define EPSG:28992 (RD Coordinates) Rijksdriehoekstelsel
proj4.defs('EPSG:28992', epsg28992Projection)

export function PotentialProjects() {
  const { id, index } = useParams()
  const [bagAnalyzers, setBagAnalyzers] = useState<BagAnalyzers | null>(null)
  const [adresses, setAdresses] = useState<PotentialAddress[]>([])
  const [sortBy, setSortBy] = useState<'index' | 'bagNumsCount' | 'distance'>('index')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [viewState, setViewState] = useState<ViewState>({
    longitude: 5.278949276896185,
    latitude: 52.09575758038787,
    zoom: 7,
  })
  const mapRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()

  const saveData = useCallback(() => {
    navigate(location.pathname, {
      state: { viewState },
      replace: true,
    })
  }, [navigate, location.pathname, viewState])

  const debouncedSaveData = useCallback(debounce(saveData, 100), [saveData])

  useEffect(() => {
    debouncedSaveData()
  }, [viewState, location.pathname, debouncedSaveData])

  useEffect(() => {
    if (location.state && (location.state as { viewState: ViewState }).viewState) {
      setViewState((location.state as { viewState: ViewState }).viewState)
    }
  }, [])

  const bagAnalyzersQuery = useQuery({
    queryKey: ['bagAnalyzers', id],
    queryFn: async () => {
      const response = await fetch(`/api/bag/analyzer/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  // Handle data sorting only once after the data is fetched
  useEffect(() => {
    if (bagAnalyzersQuery.data) {
      const sortedProjects = [...bagAnalyzersQuery.data.potentialProjects].sort((a, b) => a.index - b.index)
      setBagAnalyzers({ ...bagAnalyzersQuery.data, potentialProjects: sortedProjects })
    }
  }, [bagAnalyzersQuery.data])

  const potentialAddressQuery = useQuery({
    queryKey: ['potentialAddress', id, index],
    enabled: !!index,
    queryFn: async () => {
      const response = await fetch(`/api/bag/analyzer/${id}/${index}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (!index) {
      setAdresses([])
    }
    if (potentialAddressQuery.data) {
      setAdresses(potentialAddressQuery.data)
    }
  }, [potentialAddressQuery.data, index])

  const handleSort = (field: 'index' | 'bagNumsCount' | 'distance') => {
    const newSortOrder = sortBy === field && sortOrder === 'asc' ? 'desc' : 'asc'
    setSortBy(field)
    setSortOrder(newSortOrder)
  }

  const potentialProjectsGeoJson: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection', // Explicitly set the type to "FeatureCollection"
    features: bagAnalyzers?.potentialProjects.map((project) => {
      const coordinates = project.location.centerPoint
        .replace('POINT(', '')
        .replace(')', '')
        .split(' ')
      const [x, y] = [
        Number.parseFloat(coordinates[0]),
        Number.parseFloat(coordinates[1]),
      ]
      const [longitude, latitude] = proj4('EPSG:28992', 'WGS84', [x, y])

      return {
        type: 'Feature',
        properties: {
          id: project.id,
          index: project.index,
          bagNumsCount: project.bagNumsCount,
          location: project.location,
          closestProject: project.closestProject,
        },
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      }
    }) || [],
  }

  const potentialAddressGeoJson: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection', // Explicitly set the type to "FeatureCollection"
    features: adresses.map((project) => {
      const coordinates = project.geometry
        .replace('POINT(', '')
        .replace(')', '')
        .split(' ')
      const [x, y] = [
        Number.parseFloat(coordinates[0]),
        Number.parseFloat(coordinates[1]),
      ]
      const [longitude, latitude] = proj4('EPSG:28992', 'WGS84', [x, y])

      return {
        type: 'Feature',
        properties: {
          num: project.num,
          street: project.street,
          city: project.city,
          homeNumber: project.homeNumber,
          homeLetter: project.homeLetter,
          homeAddition: project.homeAddition,
          zipcode: project.zipcode,
          geometry: project.geometry,
        },
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      }
    }) || [],
  }

  React.useEffect(() => {
    // Fly to the selected project
    if (index && bagAnalyzers && !Number.isNaN(Number(index)) && bagAnalyzers.potentialProjects[Number(index)]) {
      const project = bagAnalyzers.potentialProjects[Number(index)]
      const coordinates = project.location.centerPoint
        .replace('POINT(', '')
        .replace(')', '')
        .split(' ')
      const [x, y] = [
        Number.parseFloat(coordinates[0]),
        Number.parseFloat(coordinates[1]),
      ]
      const [longitude, latitude] = proj4('EPSG:28992', 'WGS84', [x, y])

      // Calculate zoom level based on the centerPointRadius
      const zoom = Math.floor(17 - Math.log2(project.location.centerPointRadius / 100))

      setViewState({
        longitude,
        latitude,
        zoom,
      })
    }
  }, [index, bagAnalyzers])

  const sortedProjects = React.useMemo(() => {
    if (!bagAnalyzers)
      return []

    return [...bagAnalyzers.potentialProjects].sort((a, b) => {
      const aValue
      = sortBy === 'index'
        ? a.index
        : sortBy === 'bagNumsCount'
          ? a.bagNumsCount
          : a.closestProject.distance
      const bValue
      = sortBy === 'index'
        ? b.index
        : sortBy === 'bagNumsCount'
          ? b.bagNumsCount
          : b.closestProject.distance

      return sortOrder === 'asc' ? aValue - bValue : bValue - aValue
    })
  }, [bagAnalyzers, sortBy, sortOrder])

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={8}>
            <Card className="card-outline card-primary">
              <Card.Header>
                <Card.Title>
                  Potentiele projecten
                  {' '}
                  {index && bagAnalyzers && !Number.isNaN(Number(index)) && bagAnalyzers.potentialProjects[Number(index)] && `>> ${bagAnalyzers.potentialProjects[Number(index)].location.city.name} (idx ${index})`}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th onClick={() => handleSort('index')} style={{ cursor: 'pointer' }}>
                        Index
                        {' '}
                        {sortBy === 'index' && (sortOrder === 'asc' ? '↑' : '↓')}
                      </th>
                      <th onClick={() => handleSort('bagNumsCount')} style={{ cursor: 'pointer' }}>
                        Aantal
                        {' '}
                        {sortBy === 'bagNumsCount' && (sortOrder === 'asc' ? '↑' : '↓')}
                      </th>
                      <th>Plaats</th>
                      <th>Dichtsbijzijnde project</th>
                      <th onClick={() => handleSort('distance')} style={{ cursor: 'pointer' }}>
                        Afstand Project
                        {' '}
                        {sortBy === 'distance' && (sortOrder === 'asc' ? '↑' : '↓')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedProjects.map(project => (
                      <tr
                        key={project.id}
                        onClick={() => navigate(`/bag/bufinder/${id}/${project.index}`)}
                        onDoubleClick={() => navigate(`/bag/bufinder/${id}/${project.index}/detail`)}
                        className={index && Number(index) === project.index ? 'table-active' : ''}
                      >
                        <td>{project.index}</td>
                        <td>{project.bagNumsCount}</td>
                        <td>{project.location.city.name}</td>
                        <td>{project.closestProject.name}</td>
                        <td>{prettyDistance(project.closestProject.distance)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Mapbox.Map
              mapboxAccessToken={MAPBOX_TOKEN}
              mapStyle="mapbox://styles/metrotyranno/clnqoglz6009001pa563c1zz0"
              style={{
                height: 'calc(100vh - 56px - 16px - 16px)',
              }}
              {...viewState}
              onMove={evt => setViewState(evt.viewState)}
              attributionControl={false}
              projection={{ name: 'globe' }}
              ref={mapRef}
            >
              <MapWithLayers potentialProjectsGeoJson={potentialProjectsGeoJson} potentialAddressesGeoJson={potentialAddressGeoJson} />
            </Mapbox.Map>
          </Col>
        </Row>
      </Container>

    </>
  )
}
